import { useAuthStore } from "~/store/auth/auth.store";

/** prefix .client means its only loaded from client side **/
/** we can access to authStore because auth-plugin is loaded before localize-plugin **/

export default defineNuxtPlugin(() => {
  const authStore = useAuthStore();
  const localize: any = (window as any).Localize;

  if (authStore.isLoggedIn() && localize) {
    localize.setLanguage(authStore.user?.language);
  }

  return {
    provide: {
      Localize: localize,
    },
  };
});
