import { useTokens } from "~/composables/auth/useTokens";
import { useAuthStore } from "~/store/auth/auth.store";
import type { IToken } from "~/utils/interfaces/auth-interfaces";

export default defineNuxtPlugin(async () => {
  const authStore = useAuthStore();
  const tokens = useTokens();
  const config = useRuntimeConfig();

  if (config.public.ENV !== "PROD") {
    // eslint-disable-next-line
    console.log("Auth Plugin");
  }

  const _tokens: IToken | null = tokens.getTokensFromCookies();

  if (_tokens) {
    await authStore.setTokens(_tokens);
    await authStore.refreshToken();
    await authStore.login();
  }
});
