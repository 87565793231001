import * as rudderAnalytics from "rudder-sdk-js";

export default defineNuxtPlugin((nuxtApp) => {
  const environment = nuxtApp.$config.public.ENV;

  if (["DEV", "PROD"].includes(environment)) {
    rudderAnalytics.load(nuxtApp.$config.public.RUDDERSTACK_KEY, nuxtApp.$config.public.RUDDERSTACK_URL);
    return {
      provide: {
        Rudderstack: rudderAnalytics,
      },
    };
  } else {
    return {};
  }
});
