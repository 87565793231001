import * as Sentry from "@sentry/nuxt";
import { useAuthStore } from "~/store/auth/auth.store";

export default defineNuxtPlugin((nuxtApp) => {
  const { vueApp } = nuxtApp;
  const authStore = useAuthStore();
  const environment = nuxtApp.$config.public.ENV;
  const appVersion = nuxtApp.$config.public.APP_VERSION;

  if (["DEV", "PROD"].includes(environment)) {
    Sentry.setUser({
      id: authStore.user?.user_id ?? "-",
      email: authStore.user?.email ?? "-",
    });
    Sentry.init({
      // debug: true,
      dsn: nuxtApp.$config.public.SENTRY_DSN,
      environment,
      ...(appVersion && { release: appVersion }),
      trackComponents: true,
      timeout: 2000,
      integrations: [
        Sentry.vueIntegration(),
        Sentry.browserTracingIntegration(),
        Sentry.browserProfilingIntegration(),
        Sentry.piniaIntegration(usePinia()),
        Sentry.replayIntegration({
          // Additional SDK configuration goes in here, for example:
          maskAllText: false,
          blockAllMedia: true,
          networkDetailAllowUrls: [
            window.location.origin,
            /^https:\/\/app\.simbel\.fr/,
            /^https:\/\/orders\.production\.simbel\.io/,
            /^https:\/\/trainings\.production\.simbel\.io/,
          ],
          networkCaptureBodies: true,
          networkRequestHeaders: ["authorization"],
        }),
      ],
      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      tracesSampleRate: environment === "PROD" ? 0.5 : 0,
      replaysSessionSampleRate: environment === "PROD" ? 0.2 : 0,
      replaysOnErrorSampleRate: environment === "PROD" ? 1 : 0,
      profilesSampleRate: 0.5,

      attachProps: true,
      beforeSend(event, hint) {
        Sentry.setUser({
          id: authStore.user?.user_id ?? "-",
          email: authStore.user?.email ?? "-",
        });
        /*         // Check if it is an exception, and if so, log it.
        if (event.exception) {
          // eslint-disable-next-line no-console
          console.error(`[Exception handled by Sentry]: (${hint.originalException})`, { event, hint });
        } */
        // Continue sending to Sentry
        return event;
      },
    });
  }
});
