export const appHead = {"meta":[{"name":"viewport","content":"width=device-width, initial-scale=1, maximum-scale=1"},{"charset":"utf-16"},{"name":"Simbel For Teams","content":""}],"link":[{"rel":"icon","type":"image/png","href":"/icons/favicon.png"}],"style":[],"script":[{"src":"https://global.localizecdn.com/localize.js"},{"innerHTML":"!function(a){if(!a.Localize){a.Localize={};for(var e=[\"translate\",\"untranslate\",\"phrase\",\"initialize\",\"translatePage\",\"setLanguage\",\"getLanguage\",\"detectLanguage\",\"getAvailableLanguages\",\"untranslatePage\",\"bootstrap\",\"prefetch\",\"on\",\"off\",\"hideWidget\",\"showWidget\",\"getSourceLanguage\"],t=0;t<e.length;t++)a.Localize[e[t]]=function(){}}}(window);","type":"text/javascript"},{"innerHTML":"\n                        Localize.initialize({\n                            key: '67f0e8051c28a',\n                            rememberLanguage: true,\n                            saveNewPhrases: true,\n                            retranslateOnNewPhrases: true\n                            // other options go here, separated by commas\n                          });\n                    "},{"innerHTML":"!function(e,t,n){function a(){var e=t.getElementsByTagName(\"script\")[0],n=t.createElement(\"script\");n.type=\"text/javascript\",n.async=!0,n.src=\"https://beacon-v2.helpscout.net\",e.parentNode.insertBefore(n,e)}if(e.Beacon=n=function(t,n,a){e.Beacon.readyQueue.push({method:t,options:n,data:a})},n.readyQueue=[],\"complete\"===t.readyState)return a();e.attachEvent?e.attachEvent(\"onload\",a):e.addEventListener(\"load\",a,!1)}(window,document,window.Beacon||function(){});","type":"text/javascript"},{"innerHTML":"window.Beacon('init', 'acc5ed04-de3b-44e7-8dfd-9597fc89563b')","type":"text/javascript"},{"src":"https://www.youtube.com/iframe_api","async":true,"defer":true}],"noscript":[],"charset":"utf-16","viewport":"width=device-width, initial-scale=1, maximum-scale=1","title":"Simbel For Teams"}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const renderJsonPayloads = false

export const componentIslands = false

export const payloadExtraction = true

export const appManifest = true

export const remoteComponentIslands = false

export const devPagesDir = null

export const devRootDir = null

export const nuxtLinkDefaults = {"componentName":"NuxtLink"}

export const asyncDataDefaults = {"deep":true}

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'