import { useCookie } from "#app";
import type { IToken } from "~/utils/interfaces/auth-interfaces";

export const useTokens = () => {
  function getTokensFromCookies(): IToken | null {
    const teams_access_token = useCookie("teams_access_token");
    const teams_refresh_token = useCookie("teams_refresh_token");

    if (teams_access_token.value && teams_refresh_token.value) {
      return {
        access_token: teams_access_token.value,
        refresh_token: teams_refresh_token.value,
      };
    }
    return null;
  }

  function setCookiesTokens(tokens: IToken): void {
    const cookies_opts = {
      expires: new Date(new Date().getDate() + 1),
      maxAge: 60 * 60 * 24,
      path: "/",
    };

    const teams_access_token = useCookie("teams_access_token", cookies_opts);
    const teams_refresh_token = useCookie("teams_refresh_token", cookies_opts);

    teams_access_token.value = tokens.access_token;
    teams_refresh_token.value = tokens.refresh_token;
  }

  function clearCookiesTokens() {
    const teams_access_token = useCookie("teams_access_token");
    const teams_refresh_token = useCookie("teams_refresh_token");

    const teams_access_token_global = useCookie("teams_access_token", {
      domain: "simbel.fr",
    });
    const teams_refresh_token_global = useCookie("teams_refresh_token", {
      domain: "simbel.fr",
    });

    teams_access_token.value = null;
    teams_refresh_token.value = null;
    teams_access_token_global.value = null;
    teams_refresh_token_global.value = null;
  }

  return { getTokensFromCookies, setCookiesTokens, clearCookiesTokens };
};
