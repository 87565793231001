export enum RolesEnums {
  COMPANY_ADMIN = "company_admin",
  GROUP_ADMIN = "group_admin",
  EMPLOYEE = "employee",
  AUTHOR = "author",
  GROUP_VIEWER = "group_viewer",
  MANAGER = "manager",
  TRAINER_USER = "trainer_user",
  TRAINING_PLAN_ADMIN = "training_plan_admin",
  TRAINING_ADMIN = "training_admin",
  SIMBEL_ADMIN = "simbel_admin",
}
