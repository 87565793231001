import { Configuration as OrderConfiguration } from "@simbelapp/order-sdk";
import { Configuration as BillingConfiguration } from "@simbelapp/billing-sdk";
import { Configuration as AuthConfiguration } from "@simbelapp/auth-sdk";
import { Configuration as TrainingsConfiguration } from "@simbelapp/trainings-sdk";

import { useAuthStore } from "~/store/auth/auth.store";
export type MicroServiceType = "orders" | "orders_reporting" | "billing" | "auth" | "trainings";

const ConfigurationMap: Record<MicroServiceType, new (config: any) => any> = {
  orders: OrderConfiguration,
  orders_reporting: OrderConfiguration,
  billing: BillingConfiguration,
  auth: AuthConfiguration,
  trainings: TrainingsConfiguration,
};

export async function useSDKApi<T>(
  ms: MicroServiceType,
  ApiClass: new (config: any) => T,
  isBearer = true,
): Promise<T> {
  const config = useRuntimeConfig();
  const authStore = useAuthStore();

  const Map_MS = {
    auth: config.public.AUTH_URL,
    orders: config.public.ORDERS_URL,
    orders_reporting: config.public.ORDERS_REPORTING_API_URL,
    trainings: config.public.TRAININGS_URL,
    billing: config.public.BILLING_URL as string,
  };

  if (isBearer && !authStore.isAccessTokenValid() && authStore.isRefreshTokenValid()) await authStore.refreshToken();

  const token = authStore.tokens?.access_token;

  const ConfigurationClass = ConfigurationMap[ms];
  const apiConfig = new ConfigurationClass({
    basePath: Map_MS[ms],
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  const apiInstance = new ApiClass(apiConfig);

  return apiInstance;
}
