import { type UseFetchOptions } from "#app";
import { useFetch } from "#imports";
import { useAuthStore } from "~/store/auth/auth.store";

export type MicroServiceApiType = "auth" | "orders" | "trainings";

export async function useFetchApi(
  ms: MicroServiceApiType,
  url: string,
  opts?: UseFetchOptions<any, any, any>,
  isBearer = true,
) {
  const config = useRuntimeConfig();
  const authStore = useAuthStore();

  const Map_MS = {
    auth: config.public.AUTH_URL,
    orders: config.public.ORDERS_URL,
    trainings: config.public.TRAININGS_URL,
  };

  if (isBearer && !authStore.isAccessTokenValid() && authStore.isRefreshTokenValid()) await authStore.refreshToken();

  const token = authStore.tokens?.access_token;

  return useFetch(url, {
    baseURL: Map_MS[ms],
    key: url,
    headers: {
      ...(isBearer && { Authorization: `Bearer ${token}` }),
    },
    ...opts,
  });
}
