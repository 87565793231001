/** prefix .client means its only loaded from client side **/
/** we can access to authStore because auth-plugin is loaded before localize-plugin **/

export default defineNuxtPlugin(() => {
  const helpscout: any = (window as any).Beacon;

  return {
    provide: {
      Helpscout: helpscout,
    },
  };
});
