import { default as indexJfTsfuGhATMeta } from "/app/pages/admin/contents/index.vue?macro=true";
import { default as indexrUgIAyoLGdMeta } from "/app/pages/admin/display/index.vue?macro=true";
import { default as EditTrainingPartnerModal65ntATJXWlMeta } from "/app/pages/admin/log-as/EditTrainingPartnerModal.vue?macro=true";
import { default as indexQGWzPLK3LDMeta } from "/app/pages/admin/log-as/index.vue?macro=true";
import { default as index6E7Uzbc1rVMeta } from "/app/pages/admin/my-requests/index.vue?macro=true";
import { default as indexvJKqjSbZLzMeta } from "/app/pages/catalog/index.vue?macro=true";
import { default as indexQdMXO1eOkzMeta } from "/app/pages/company-catalog/index.vue?macro=true";
import { default as indexhg2nWJrbRRMeta } from "/app/pages/dashboard/index.vue?macro=true";
import { default as index0MCgyG25GHMeta } from "/app/pages/habilitations/index.vue?macro=true";
import { default as index4aGTGBC5tJMeta } from "/app/pages/home/index.vue?macro=true";
import { default as indexN6pT4Un8hYMeta } from "/app/pages/index.vue?macro=true";
import { default as indexHygUjNI2NoMeta } from "/app/pages/my-catalog/index.vue?macro=true";
import { default as indexeb3KCcoyd0Meta } from "/app/pages/my-sessions/index.vue?macro=true";
import { default as _91my_training_id_93E1aDhWwI63Meta } from "/app/pages/my-trainings/[my_training_id].vue?macro=true";
import { default as index5S2gfrhkXnMeta } from "/app/pages/my-trainings/index.vue?macro=true";
import { default as scorm_45playerwRPr2sdjFsMeta } from "/app/pages/my-trainings/scorm-player.vue?macro=true";
import { default as _91request_id_93UPa61zgqPvMeta } from "/app/pages/requests/[request_id].vue?macro=true";
import { default as indexpYlP26iY0pMeta } from "/app/pages/requests/index.vue?macro=true";
import { default as index11eXGkSJBcMeta } from "/app/pages/settings/index.vue?macro=true";
import { default as callbackBgNum0R3ApMeta } from "/app/pages/sso/callback.vue?macro=true";
import { default as redirectaorrRKLnFiMeta } from "/app/pages/sso/redirect.vue?macro=true";
import { default as indexZxDxKCcaBGMeta } from "/app/pages/training-plan/index.vue?macro=true";
import { default as indexnq54o81oozMeta } from "/app/pages/users/index.vue?macro=true";
export default [
  {
    name: indexJfTsfuGhATMeta?.name ?? "admin-contents___fr",
    path: indexJfTsfuGhATMeta?.path ?? "/admin/contents",
    meta: indexJfTsfuGhATMeta || {},
    alias: indexJfTsfuGhATMeta?.alias || [],
    redirect: indexJfTsfuGhATMeta?.redirect || undefined,
    component: () => import("/app/pages/admin/contents/index.vue").then(m => m.default || m)
  },
  {
    name: indexrUgIAyoLGdMeta?.name ?? "admin-display___fr",
    path: indexrUgIAyoLGdMeta?.path ?? "/admin/display",
    meta: indexrUgIAyoLGdMeta || {},
    alias: indexrUgIAyoLGdMeta?.alias || [],
    redirect: indexrUgIAyoLGdMeta?.redirect || undefined,
    component: () => import("/app/pages/admin/display/index.vue").then(m => m.default || m)
  },
  {
    name: EditTrainingPartnerModal65ntATJXWlMeta?.name ?? "admin-log-as-EditTrainingPartnerModal___fr",
    path: EditTrainingPartnerModal65ntATJXWlMeta?.path ?? "/admin/log-as/EditTrainingPartnerModal",
    meta: EditTrainingPartnerModal65ntATJXWlMeta || {},
    alias: EditTrainingPartnerModal65ntATJXWlMeta?.alias || [],
    redirect: EditTrainingPartnerModal65ntATJXWlMeta?.redirect || undefined,
    component: () => import("/app/pages/admin/log-as/EditTrainingPartnerModal.vue").then(m => m.default || m)
  },
  {
    name: indexQGWzPLK3LDMeta?.name ?? "admin-log-as___fr",
    path: indexQGWzPLK3LDMeta?.path ?? "/admin/log-as",
    meta: indexQGWzPLK3LDMeta || {},
    alias: indexQGWzPLK3LDMeta?.alias || [],
    redirect: indexQGWzPLK3LDMeta?.redirect || undefined,
    component: () => import("/app/pages/admin/log-as/index.vue").then(m => m.default || m)
  },
  {
    name: index6E7Uzbc1rVMeta?.name ?? "admin-my-requests___fr",
    path: index6E7Uzbc1rVMeta?.path ?? "/admin/my-requests",
    meta: index6E7Uzbc1rVMeta || {},
    alias: index6E7Uzbc1rVMeta?.alias || [],
    redirect: index6E7Uzbc1rVMeta?.redirect || undefined,
    component: () => import("/app/pages/admin/my-requests/index.vue").then(m => m.default || m)
  },
  {
    name: indexvJKqjSbZLzMeta?.name ?? "catalog___fr",
    path: indexvJKqjSbZLzMeta?.path ?? "/catalog",
    meta: indexvJKqjSbZLzMeta || {},
    alias: indexvJKqjSbZLzMeta?.alias || [],
    redirect: indexvJKqjSbZLzMeta?.redirect || undefined,
    component: () => import("/app/pages/catalog/index.vue").then(m => m.default || m)
  },
  {
    name: indexQdMXO1eOkzMeta?.name ?? "company-catalog___fr",
    path: indexQdMXO1eOkzMeta?.path ?? "/company-catalog",
    meta: indexQdMXO1eOkzMeta || {},
    alias: indexQdMXO1eOkzMeta?.alias || [],
    redirect: indexQdMXO1eOkzMeta?.redirect || undefined,
    component: () => import("/app/pages/company-catalog/index.vue").then(m => m.default || m)
  },
  {
    name: indexhg2nWJrbRRMeta?.name ?? "dashboard___fr",
    path: indexhg2nWJrbRRMeta?.path ?? "/dashboard",
    meta: indexhg2nWJrbRRMeta || {},
    alias: indexhg2nWJrbRRMeta?.alias || [],
    redirect: indexhg2nWJrbRRMeta?.redirect || undefined,
    component: () => import("/app/pages/dashboard/index.vue").then(m => m.default || m)
  },
  {
    name: index0MCgyG25GHMeta?.name ?? "habilitations___fr",
    path: index0MCgyG25GHMeta?.path ?? "/habilitations",
    meta: index0MCgyG25GHMeta || {},
    alias: index0MCgyG25GHMeta?.alias || [],
    redirect: index0MCgyG25GHMeta?.redirect || undefined,
    component: () => import("/app/pages/habilitations/index.vue").then(m => m.default || m)
  },
  {
    name: index4aGTGBC5tJMeta?.name ?? "home___fr",
    path: index4aGTGBC5tJMeta?.path ?? "/home",
    meta: index4aGTGBC5tJMeta || {},
    alias: index4aGTGBC5tJMeta?.alias || [],
    redirect: index4aGTGBC5tJMeta?.redirect || undefined,
    component: () => import("/app/pages/home/index.vue").then(m => m.default || m)
  },
  {
    name: indexN6pT4Un8hYMeta?.name ?? "index___fr",
    path: indexN6pT4Un8hYMeta?.path ?? "/",
    meta: indexN6pT4Un8hYMeta || {},
    alias: indexN6pT4Un8hYMeta?.alias || [],
    redirect: indexN6pT4Un8hYMeta?.redirect || undefined,
    component: () => import("/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexHygUjNI2NoMeta?.name ?? "my-catalog___fr",
    path: indexHygUjNI2NoMeta?.path ?? "/my-catalog",
    meta: indexHygUjNI2NoMeta || {},
    alias: indexHygUjNI2NoMeta?.alias || [],
    redirect: indexHygUjNI2NoMeta?.redirect || undefined,
    component: () => import("/app/pages/my-catalog/index.vue").then(m => m.default || m)
  },
  {
    name: indexeb3KCcoyd0Meta?.name ?? "my-sessions___fr",
    path: indexeb3KCcoyd0Meta?.path ?? "/my-sessions",
    meta: indexeb3KCcoyd0Meta || {},
    alias: indexeb3KCcoyd0Meta?.alias || [],
    redirect: indexeb3KCcoyd0Meta?.redirect || undefined,
    component: () => import("/app/pages/my-sessions/index.vue").then(m => m.default || m)
  },
  {
    name: _91my_training_id_93E1aDhWwI63Meta?.name ?? "my-trainings-my_training_id___fr",
    path: _91my_training_id_93E1aDhWwI63Meta?.path ?? "/my-trainings/:my_training_id()",
    meta: _91my_training_id_93E1aDhWwI63Meta || {},
    alias: _91my_training_id_93E1aDhWwI63Meta?.alias || [],
    redirect: _91my_training_id_93E1aDhWwI63Meta?.redirect || undefined,
    component: () => import("/app/pages/my-trainings/[my_training_id].vue").then(m => m.default || m)
  },
  {
    name: index5S2gfrhkXnMeta?.name ?? "my-trainings___fr",
    path: index5S2gfrhkXnMeta?.path ?? "/my-trainings",
    meta: index5S2gfrhkXnMeta || {},
    alias: index5S2gfrhkXnMeta?.alias || [],
    redirect: index5S2gfrhkXnMeta?.redirect || undefined,
    component: () => import("/app/pages/my-trainings/index.vue").then(m => m.default || m)
  },
  {
    name: scorm_45playerwRPr2sdjFsMeta?.name ?? "my-trainings-scorm-player___fr",
    path: scorm_45playerwRPr2sdjFsMeta?.path ?? "/my-trainings/scorm-player",
    meta: scorm_45playerwRPr2sdjFsMeta || {},
    alias: scorm_45playerwRPr2sdjFsMeta?.alias || [],
    redirect: scorm_45playerwRPr2sdjFsMeta?.redirect || undefined,
    component: () => import("/app/pages/my-trainings/scorm-player.vue").then(m => m.default || m)
  },
  {
    name: _91request_id_93UPa61zgqPvMeta?.name ?? "requests-request_id___fr",
    path: _91request_id_93UPa61zgqPvMeta?.path ?? "/requests/:request_id()",
    meta: _91request_id_93UPa61zgqPvMeta || {},
    alias: _91request_id_93UPa61zgqPvMeta?.alias || [],
    redirect: _91request_id_93UPa61zgqPvMeta?.redirect || undefined,
    component: () => import("/app/pages/requests/[request_id].vue").then(m => m.default || m)
  },
  {
    name: indexpYlP26iY0pMeta?.name ?? "requests___fr",
    path: indexpYlP26iY0pMeta?.path ?? "/requests",
    meta: indexpYlP26iY0pMeta || {},
    alias: indexpYlP26iY0pMeta?.alias || [],
    redirect: indexpYlP26iY0pMeta?.redirect || undefined,
    component: () => import("/app/pages/requests/index.vue").then(m => m.default || m)
  },
  {
    name: index11eXGkSJBcMeta?.name ?? "settings___fr",
    path: index11eXGkSJBcMeta?.path ?? "/settings",
    meta: index11eXGkSJBcMeta || {},
    alias: index11eXGkSJBcMeta?.alias || [],
    redirect: index11eXGkSJBcMeta?.redirect || undefined,
    component: () => import("/app/pages/settings/index.vue").then(m => m.default || m)
  },
  {
    name: callbackBgNum0R3ApMeta?.name ?? "sso-callback___fr",
    path: callbackBgNum0R3ApMeta?.path ?? "/sso/callback",
    meta: callbackBgNum0R3ApMeta || {},
    alias: callbackBgNum0R3ApMeta?.alias || [],
    redirect: callbackBgNum0R3ApMeta?.redirect || undefined,
    component: () => import("/app/pages/sso/callback.vue").then(m => m.default || m)
  },
  {
    name: redirectaorrRKLnFiMeta?.name ?? "sso-redirect___fr",
    path: redirectaorrRKLnFiMeta?.path ?? "/sso/redirect",
    meta: redirectaorrRKLnFiMeta || {},
    alias: redirectaorrRKLnFiMeta?.alias || [],
    redirect: redirectaorrRKLnFiMeta?.redirect || undefined,
    component: () => import("/app/pages/sso/redirect.vue").then(m => m.default || m)
  },
  {
    name: indexZxDxKCcaBGMeta?.name ?? "training-plan___fr",
    path: indexZxDxKCcaBGMeta?.path ?? "/training-plan",
    meta: indexZxDxKCcaBGMeta || {},
    alias: indexZxDxKCcaBGMeta?.alias || [],
    redirect: indexZxDxKCcaBGMeta?.redirect || undefined,
    component: () => import("/app/pages/training-plan/index.vue").then(m => m.default || m)
  },
  {
    name: indexnq54o81oozMeta?.name ?? "users___fr",
    path: indexnq54o81oozMeta?.path ?? "/users",
    meta: indexnq54o81oozMeta || {},
    alias: indexnq54o81oozMeta?.alias || [],
    redirect: indexnq54o81oozMeta?.redirect || undefined,
    component: () => import("/app/pages/users/index.vue").then(m => m.default || m)
  }
]