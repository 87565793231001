export enum ItemType {
  SESSION = "session",
  BOOKING = "booking",
  REQUEST = "request",
  INVITE = "invite",
  PROJECT = "project",
}

export enum VatEnum {
  TTC = "TTC",
  HT = "HT",
}

export enum FinancingModes {
  OPCO = "opco",
  CPF = "cpf",
  BUDGET = "budget",
}

export enum MicroserviceEnum {
  ORDERS = "orders",
  ORDERS_REPORTING = "orders_reporting",
  BILLING = "billing",
  USERS = "auth",
  TRAININGS = "trainings",
}

export enum GridStyle {
  MAIN = "main",
  SECONDARY = "secondary",
}

export enum SimbelEmail {
  CLAIRE = "claire@simbel.fr",
}
