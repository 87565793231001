export enum CONNEXION_STEPS {
  LOGIN_EMAIL,
  LOGIN_PASSWORD,
  REGISTER_NAME,
  REGISTER_PASSWORD,
  RESET_PASSWORD,
  VALIDATE_EMAIL,
}

export enum AuthStrategy {
  EMAIL_PASSWORD = "email_password",
  SSO_SAML = "sso_saml",
}
