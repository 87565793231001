import { useFetchApi } from "~/composables/api/useFetchApi";
import { useAuthStore } from "~/store/auth/auth.store";

export const useAuthApi = () => {
  async function fetchProfile() {
    return await useFetchApi("auth", "/users/profile", {
      method: "GET",
      pick: ["user_id", "email", "employee_number", "firstname", "lastname", "language", "main_entity_id", "roles"],
    });
  }

  async function logAttempt(email: string) {
    return await useFetchApi(
      "auth",
      "/auth/teams/log-attempt",
      {
        method: "POST",
        body: {
          email,
        },
      },
      false,
    );
  }

  async function login(email: string, password: string) {
    return await useFetchApi(
      "auth",
      "/auth/teams/login",
      {
        method: "POST",
        body: {
          email,
          password,
        },
        pick: ["access_token", "refresh_token"],
      },
      false,
    );
  }

  async function register(email: string, password: string) {
    return await useFetchApi(
      "auth",
      "/auth/teams/register",
      {
        method: "POST",
        body: {
          email,
          password,
        },
      },
      false,
    );
  }

  async function generateResetPassword(email: string) {
    return await useFetchApi(
      "auth",
      "/auth/teams/generate-reset-token",
      {
        method: "PATCH",
        body: {
          email,
        },
      },
      false,
    );
  }

  async function resetPassword(reset_token: string, password: string) {
    return await useFetchApi(
      "auth",
      "/auth/teams/reset-password",
      {
        method: "PATCH",
        body: {
          reset_token,
          password,
        },
      },
      false,
    );
  }

  async function ssoGetAccessFromSSOToken(sso_token: string) {
    return await useFetchApi(
      "auth",
      `/auth/teams/sso/login/token/${sso_token}`,
      {
        method: "GET",
      },
      false,
    );
  }

  async function logAs(user_id: string) {
    const authStore = useAuthStore();
    return await useFetchApi("auth", "/auth/teams/log-as", {
      method: "POST",
      headers: {
        Authorization: `Bearer ${authStore.tokens?.refresh_token}`,
      },
      body: {
        user_id,
      },
    });
  }

  async function refresh() {
    const authStore = useAuthStore();
    return await useFetchApi(
      "auth",
      "/auth/teams/refresh",
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${authStore.tokens?.refresh_token}`,
        },
      },
      false,
    );
  }

  return {
    fetchProfile,
    logAttempt,
    login,
    register,
    logAs,
    generateResetPassword,
    resetPassword,
    refresh,
    ssoGetAccessFromSSOToken,
  };
};
